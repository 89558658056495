// src/features/criteria/criteriaSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    viewMode: 'table', // 'table' или 'card'
    searchQuery: '',
    selectedModule: null,
    selectedGroup: null,
};

const criteriaSlice = createSlice({
    name: 'criteria',
    initialState,
    reducers: {
        setViewMode(state, action) {
            state.viewMode = action.payload;
        },
        setSearchQuery(state, action) {
            state.searchQuery = action.payload;
        },
        setSelectedModule(state, action) {
            state.selectedModule = action.payload;
        },
        setSelectedGroup(state, action) {
            state.selectedGroup = action.payload;
        },
    },
});

export const { setViewMode, setSearchQuery, setSelectedModule, setSelectedGroup } = criteriaSlice.actions;
export default criteriaSlice.reducer;