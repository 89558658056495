import React, { useEffect, useState } from 'react';
import axios from "../../service/axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';

const Create = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [loading, setLoading] = useState(true);
    const [createISPUser, setCreateISPUser] = useState(false);
    const [submitting, setSubmitting] = useState(false);  // New state for form submission

    useEffect(() => {
        axios.get('groups/')
            .then(response => {
                setGroups(response.data.map(group => ({ value: group.id, label: group.name })));
                setLoading(false);
            })
            .catch(error => {
                console.warn(error);
                setLoading(false);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();
        const toastId = toast.loading(createISPUser ? 'Добавляют пользователя в ISPmanager' : null, {
            position: 'top-right'
        });  // Show loading toast
        setSubmitting(true);  // Set submitting state to true
        axios.post('/students/', {
            full_name: name,
            group_id: selectedGroup?.value || null,
            create_isp_user: createISPUser
        })
            .then(response => {
                toast.dismiss(toastId);  // Dismiss loading toast
                toast.success(response.data.message, {
                    position: 'top-right'
                });
                navigate(`/students/`);
            })
            .catch(error => {
                toast.dismiss(toastId);  // Dismiss loading toast
                toast.error('Данные не были обработаны', {
                    position: 'top-right'
                });
                console.warn(error);
            })
            .finally(() => {
                setSubmitting(false);  // Set submitting state to false
            });
    };

    return (
        <>
            <Typography variant="h3" gutterBottom>Создание студента</Typography>
            <form onSubmit={handleSubmit}>

                <Box sx={{ my: 2 }}>
                    <TextField
                        label="ФИО"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        variant="standard"
                        required
                    />
                </Box>
                <Box sx={{ my: 2 }}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>Группа</InputLabel>
                        <Select
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                            label="Группа"
                            required
                            disabled={loading}
                        >
                            {loading ? (
                                <MenuItem disabled>
                                    <Loader />
                                </MenuItem>
                            ) : (
                                groups.map((group) => (
                                    <MenuItem key={group.value} value={group}>
                                        {group.label}
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ my: 2 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={createISPUser}
                                onChange={(e) => setCreateISPUser(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Создать пользователя в системе ISPmanager"
                    />
                </Box>

                <Button type="submit" variant="contained" color="primary" fullWidth disabled={submitting}>
                    {submitting ? <CircularProgress size={24} /> : 'Создать'}
                </Button>
            </form>
        </>
    );
};

export default Create;