import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import DeleteDialog from "../DeleteDialog";

// Компонент таблицы с сортировкой, выбором строк и пагинацией
const EnhancedTable = ({ filteredCriterion, deleteCriterion }) => {
    // Состояние для сортировки
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('title');
    const [selected, setSelected] = useState([]);

    // Состояние для пагинации
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Функция для изменения порядка сортировки
    const handleRequestSort = (property) => {
        const isAscending = orderBy === property && order === 'asc';
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Функция для сортировки данных
    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const sortedData = filteredCriterion.sort(getComparator(order, orderBy));

    // Обработка выбора строки
    const handleRowClick = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    // Пагинация
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={orderBy === 'title' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'title'}
                                    direction={orderBy === 'title' ? order : 'asc'}
                                    onClick={() => handleRequestSort('title')}
                                >
                                    Название
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'weight' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'weight'}
                                    direction={orderBy === 'weight' ? order : 'asc'}
                                    onClick={() => handleRequestSort('weight')}
                                >
                                    Балл
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Описание</TableCell>
                            <TableCell>Группа</TableCell>
                            <TableCell>Дата создания</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((item) => (
                            <TableRow
                                key={item.id}
                                selected={selected.indexOf(item.id) !== -1}
                                onClick={() => handleRowClick(item.id)}
                            >
                                <TableCell>
                                    <Link to={`/criterion/${item.id}/`}
                                          style={{textDecoration: 'none', color: 'inherit'}}>
                                        {item.title}
                                    </Link>
                                </TableCell>
                                <TableCell>{item.weight}</TableCell>
                                <TableCell>{item.description || 'Нет описания'}</TableCell>
                                <TableCell>{item.group.name}</TableCell>
                                <TableCell>{new Date(item.created_at).toLocaleDateString()}</TableCell>
                                <TableCell>
                                    <DeleteDialog
                                        id={item.id}
                                        name={item.title}
                                        onDelete={deleteCriterion}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100, 500]}
                component="div"
                count={filteredCriterion.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={'Строк на странице:'}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default EnhancedTable;