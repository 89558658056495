import React from 'react';
import {Box, Container, Grid, Link, Typography} from "@mui/material";
import CallMadeIcon from '@mui/icons-material/CallMade';

const Index = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                paddingTop: "1rem",
                paddingBottom: "1rem",
            }}
        >
            <Container maxWidth="lg">
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle1">
                            Система Оценивания FIRPO
                        </Typography>
                    </Grid>
                    <Box
                        sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {`${new Date().getFullYear()}`}|
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle2">
                            Поддержка: <Link href="https://vk.com/malfin_beats" target="_blank"
                                             rel="noreferrer">Автор <CallMadeIcon fontSize="small"/></Link>
                        </Typography>
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
};

export default Index;