import {createSlice} from '@reduxjs/toolkit';


export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: localStorage.getItem('user') || null,
        isAuthenticated: localStorage.getItem('isAuthenticated') || null,
        token: localStorage.getItem('token') || null,
        username: localStorage.getItem('username') || null,
        first_name: null,
        last_name: null,
    },
    reducers: {
        setLogin: (state, action) => {
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('user', action.payload.user);
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('username', action.payload.username);
            state.token = action.payload.token;
            state.user = action.payload.user;
            state.isAuthenticated = true;
            state.first_name = action.payload.first_name;
            state.last_name = action.payload.last_name;
            state.username = action.payload.username;
        },
        logout: state => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('username');
            localStorage.removeItem('isAuthenticated');
            state.user = null;
            state.token = null;
            state.isAuthenticated = false;
            state.first_name = null;
            state.last_name = null;
            state.username = null;
        },
    },
});

export const {setLogin, logout} = authSlice.actions;

export default authSlice.reducer;
