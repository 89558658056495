import axios from "axios";
import {logout} from "../redux/slice/authSlice";
import store from "../redux/store";


const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API}/api/v1/`, headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
})
instance.interceptors.request.use(config => {
    const token = store.getState().auth.token;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

instance.interceptors.response.use(
    response => response,
    async (error) => {
        if (error.response?.status === 401) {
            store.dispatch(logout());
            redirectToLogin()
        }
        return Promise.reject(error);
    }
);
const redirectToLogin = () => {
    window.location.href = "/login";
}
export default instance