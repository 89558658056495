import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "../../service/axios";
import {toast} from "react-hot-toast";
import {Box, Button, TextField, Typography} from '@mui/material';

const EditStudent = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [student, setStudent] = useState({full_name: '', username: '', password: ''});

    useEffect(() => {
        axios.get(`/students/${id}/`).then(res => {
            setStudent(res.data);
        }).catch(error => {
            console.error('Ошибка при получении данных студента:', error);
        });
    }, [id]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setStudent(prevStudent => ({
            ...prevStudent,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();
        axios.put(`/students/${id}/`, student)
            .then(res => {
                toast.success(res.data.message,{
                    position: 'top-right'
                });
                navigate('/students/');
            })
            .catch(error => {
                console.error('Ошибка при обновлении студента:', error);
                toast.error('Ошибка при обновлении студента',{
                    position: 'top-right'
                });
            });
    };

    return (
        <>
            <Typography variant="h3" gutterBottom>
                Изменение студента
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                    <TextField
                        label="ФИО студента"
                        variant="standard"
                        value={student.full_name}
                        name="full_name"
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Логин"
                        variant="standard"
                        value={student.username}
                        name="username"
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Пароль"
                        variant="standard"
                        value={student.password}
                        name="password"
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Сохранить
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default EditStudent;