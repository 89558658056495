import React, {useEffect, useState} from 'react';
import axios from '../../service/axios';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {Autocomplete, Box, Button, TextField, Typography} from '@mui/material';
import Loader from "../../components/Loader";

const Create = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [maxScore, setMaxScore] = useState(0);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('/groups/')
            .then(response => {
                setGroups(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Ошибка при загрузке групп',{
                    position: 'top-right'
                });
                setLoading(false);
                console.warn(error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();
        axios.post('/modules/', {
            title,
            max_score: maxScore,
            group: selectedGroup ? parseInt(selectedGroup.value) : null
        })
            .then(response => {
                toast.success(response.data.message);
                navigate('/');
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 400 && error.response.data) {
                        Object.keys(error.response.data).forEach(key => {
                            error.response.data[key].forEach(msg => {
                                toast.error(`${key}: ${msg}`);
                            });
                        });
                    } else {
                        toast.error("Произошла ошибка на сервере.",{
                            position: 'top-right'
                        });
                    }
                } else {
                    toast.error("Произошла ошибка сети.",{
                        position: 'top-right'
                    });
                }
                console.warn(error);
            });
    };


    const handleGroupChange = (event, newValue) => {
        setSelectedGroup(newValue);
    };

    const groupOptions = groups.map(group => ({
        value: group.id,
        label: group.name
    }));

    return (
        <Box sx={{
            p: 2,
            borderRadius: 2,
            bgcolor: 'background.paper',
        }}>
            <Typography variant="h3" gutterBottom>
                Создание модуля
            </Typography>
            {loading ? (
                <Loader/>
            ) : (
                <form onSubmit={handleSubmit}>
                    <TextField
                        id="title"
                        label="Название модуля"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        value={title}
                        required
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <Autocomplete
                        disablePortal
                        id="group-select"
                        options={groupOptions}
                        value={selectedGroup}
                        required
                        onChange={handleGroupChange}
                        renderInput={(params) => <TextField {...params} label="Выберите группу" variant="standard"
                                                            required/>}
                        sx={{mb: 2}}
                        noOptionsText="Нет вариантов"
                    />
                    <TextField
                        required
                        id="max_score"
                        label="Максимальный балл"
                        type="number"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        value={maxScore}
                        onChange={(e) => setMaxScore(e.target.value)}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{my: 2}}>
                        Создать
                    </Button>
                </form>
            )}
        </Box>
    );
};

export default Create;