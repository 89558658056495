import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from "react-router-dom";
import { Box, CssBaseline, GlobalStyles } from "@mui/material";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

import Protected from "./service/Protected";

import Main from "./pages/Main";
import Login from "./pages/Auth/Login";
import Id from "./pages/Main/id";
import Create from "./pages/Main/create";
import CriteriaCreate from "./pages/Criteria/create";
import CriteriaEdit from "./pages/Criteria/edit";
import Criteria from "./pages/Criteria";
import StudentsCreate from "./pages/Students/create";
import StudentsEdit from "./pages/Students/edit";
import Students from "./pages/Students";
import Results from "./pages/Results";
import Groups from "./pages/Groups";
import GroupsCreate from "./pages/Groups/create";
import GroupsEdit from "./pages/Groups/id";
import StudentsUploads from "./pages/Students/uploads";
import Upload from "./pages/Upload";

const App = () => {
    const mode = useSelector((state) => state.theme.mode);
    const layout = useSelector((state) => state.theme.layout);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const theme = createTheme({
        palette: {
            mode,
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles styles={{ html: { scrollBehavior: 'smooth' } }} />
            <Toaster />
            <CssBaseline />
            {layout === 'header' ? <Header /> : isAuthenticated && <Sidebar />}
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                {layout === 'sidebar' && isAuthenticated && <Sidebar />}
                <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                    <Routes>
                        <Route path="/" element={<Protected />}>
                            <Route path={'/groups/'} element={<Groups />} />
                            <Route path={'/groups/create/'} element={<GroupsCreate />} />
                            <Route path={'/groups/:groupId/'} element={<GroupsEdit />} />
                            <Route path={'/'} index={true} element={<Main />} />
                            <Route path={'/module/:id/'} element={<Id />} />
                            <Route path={'/module/create/'} element={<Create />} />
                            <Route path={'/criteria/'} element={<Criteria />} />
                            <Route path={'/criterion/:id/'} element={<CriteriaEdit />} />
                            <Route path={'/criterion/create/'} element={<CriteriaCreate />} />
                            <Route path={'/students/'} element={<Students />} />
                            <Route path={'/students/:id/'} element={<StudentsEdit />} />
                            <Route path={'/students/create/'} element={<StudentsCreate />} />
                            <Route path={'/students/upload/'} element={<StudentsUploads />} />
                            <Route path={'/results/'} element={<Results />} />
                            <Route path={'/upload/'} element={<Upload />} />
                        </Route>
                        <Route path={'/login/'} element={<Login />} />
                        <Route path={'*'} element={<Protected/>} />
                    </Routes>
                </Box>
            </Box>
            <Footer />
        </ThemeProvider>
    );
};

export default App;