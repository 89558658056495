import React, {useState} from 'react';
import {Link as RouteLink} from 'react-router-dom';
import {Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {useDispatch, useSelector} from 'react-redux';
import {toggleTheme} from '../../redux/slice/themeSlice';
import LayoutSelector from '../LayoutSelector';
import {logout} from "../../redux/slice/authSlice";
import {ExpandLess, ExpandMore, Groups, Apps, Checklist, StarRate, Logout, Person, WebAsset} from "@mui/icons-material";

const Sidebar = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const mode = useSelector(state => state.theme.mode); // Получаем текущий режим темы
    const [open, setOpen] = useState(false);

    const handleToggleTheme = () => {
        dispatch(toggleTheme());
    };

    const exit = () => {
        dispatch(logout());
        window.location.href = '/login/';
    };

    const handleToggleLayoutSelector = () => {
        setOpen(!open);
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: 240,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: 240,
                    boxSizing: 'border-box',
                    backgroundColor: mode === 'light' ? '#f3f3f3' : '#212529', // Цвет фона в зависимости от темы
                    color: mode === 'light' ? '#212529' : '#f3f3f3', // Цвет текста в зависимости от темы
                },
            }}
        >
            <List>
                <ListItem>
                    <IconButton onClick={handleToggleTheme} color="inherit">
                        {mode === 'light' ? <Brightness4Icon/> : <Brightness7Icon/>}
                    </IconButton>
                    <ListItemText primary="Смена темы"/>
                </ListItem>
                <Divider/>
                <ListItem button onClick={handleToggleLayoutSelector}>
                    <ListItemIcon>
                        <WebAsset />
                    </ListItemIcon>
                    <ListItemText primary="Оформление"/>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <LayoutSelector/>
                </Collapse>
                <ListItem button component={RouteLink} to="/groups/">
                    <ListItemIcon>
                        <Groups />
                    </ListItemIcon>
                    <ListItemText primary="Группа" />
                </ListItem>
                <ListItem button component={RouteLink} to="/">
                    <ListItemIcon>
                        <Apps />
                    </ListItemIcon>
                    <ListItemText primary="Модули"/>
                </ListItem>
                <ListItem button component={RouteLink} to="/criteria/">
                    <ListItemIcon>
                        <Checklist />
                    </ListItemIcon>
                    <ListItemText primary="Критерии"/>
                </ListItem>
                <ListItem button component={RouteLink} to="/students/">
                    <ListItemIcon>
                        <Person />
                    </ListItemIcon>
                    <ListItemText primary="Студенты"/>
                </ListItem>
                <ListItem button component={RouteLink} to="/results/">
                    <ListItemIcon>
                        <StarRate />
                    </ListItemIcon>
                    <ListItemText primary="Оценивание"/>
                </ListItem>
                <ListItem button component={RouteLink} to="#" onClick={exit}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    <ListItemText primary={`Выйти (${auth.username ?? localStorage.getItem('username')})`}/>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default Sidebar;