import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography
} from '@mui/material';

export default function DeleteDialog({ id, name, onDelete }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (confirmed) => {
        setOpen(false);
        if (confirmed) {
            onDelete(id, name);
        }
    };

    return (
        <>
            <Button color="error" onClick={handleClickOpen} variant={'contained'}>Удалить</Button>
            <Dialog
                fullWidth
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                    Вы хотите удалить?
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {name}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="error" variant={'contained'}>
                        Нет
                    </Button>
                    <Button onClick={() => handleClose(true)} color="primary" variant={'contained'} autoFocus>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}