import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    mode: localStorage.getItem('theme') || 'light', // Начальное состояние: светлая тема
    layout: 'header',
    buttons: 'buttons',
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleTheme(state) {
            const newMode = state.mode === 'light' ? 'dark' : 'light';
            state.mode = newMode;
            localStorage.setItem('theme', newMode);
        },
        setLayout: (state, action) => {
            state.layout = action.payload;
        },
        setButtons: (state, action) => {
            state.buttons = action.payload;
        },
    },
});

export const {toggleTheme, setLayout, setButtons} = themeSlice.actions;

export default themeSlice.reducer;