import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../service/axios';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchQuery, setSelectedGroup, setSelectedModule, setViewMode } from '../../redux/slice/criteriaSlice';
import { Box, Button, Divider, FormControl, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import {ViewList as ViewListIcon, ViewModule as ViewModuleIcon } from '@mui/icons-material';
import Loader from "../../components/Loader";
import EnhancedTable from "../../components/EnhancedTable";
import DeleteDialog from "../../components/DeleteDialog";

const Index = () => {
    const dispatch = useDispatch();
    const { viewMode, searchQuery, selectedModule, selectedGroup } = useSelector(state => state.criteria);
    const [criterion, setCriterion] = useState([]);
    const [modules, setModules] = useState([]);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingModule, setLoadingModule] = useState(true);

    useEffect(() => {
        axios.get('modules/')
            .then(response => {
                setModules(response.data);
                setLoadingModule(false);
            })
            .catch(error => console.warn(error));

        axios.get('groups/')
            .then(response => {
                setGroups(response.data);
                setLoading(false);
            })
            .catch(error => console.warn(error));

        axios.get('criterion/')
            .then(response => {
                setCriterion(response.data);
                setLoading(false);
            })
            .catch(error => console.warn(error));
    }, []);

    const handleModuleChange = (event) => {
        dispatch(setSelectedModule(event.target.value));
        dispatch(setSelectedGroup(null));
    };

    const handleGroupChange = (event) => {
        dispatch(setSelectedGroup(event.target.value));
        dispatch(setSelectedModule(null));
    };

    const handleSearch = (e) => {
        dispatch(setSearchQuery(e.target.value));
    };

    useEffect(() => {
        if (selectedModule) {
            axios.get(`module/${selectedModule}/criteria/`)
                .then(response => {
                    setCriterion(response.data);
                })
                .catch(error => console.warn(error));
        } else if (selectedGroup) {
            axios.get(`module/${selectedGroup}/groups/`)
                .then(response => {
                    setCriterion(response.data);
                })
                .catch(error => console.warn(error));
        } else {
            axios.get('criterion/')
                .then(response => {
                    setCriterion(response.data);
                })
                .catch(error => console.warn(error));
        }
    }, [selectedModule, selectedGroup]);

    const deleteCriterion = async (id) => {
        toast.dismiss();
        try {
            const response = await axios.delete(`/criterion/${id}/`);
            setCriterion(criterion.filter(criteria => criteria.id !== id));
            toast.success(response.data.message, {
                position: 'top-right'
            });
        } catch (error) {
            console.warn(error);
        }
    };

    const filteredCriterion = criterion.filter(item =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const moduleOptions = modules.map(module => (
        <MenuItem key={module.id} value={module.id}>
            {`${module.title} (${module.group.name})`}
        </MenuItem>
    ));

    const groupOptions = groups.map(group => (
        <MenuItem key={group.id} value={group.id}>
            {group.name}
        </MenuItem>
    ));

    const toggleView = () => {
        dispatch(setViewMode(viewMode === 'table' ? 'card' : 'table'));
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                mb: 2
            }}>
                <Typography variant="h3">
                    Критерии
                </Typography>
                <Box>
                    <Button variant="contained" color="success" component={Link} to="/criterion/create" sx={{ mr: 2 }}>
                        Создать
                    </Button>
                    <IconButton color="primary" onClick={toggleView}>
                        {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                    </IconButton>
                </Box>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <FormControl fullWidth margin="normal">
                    <Select
                        variant="standard"
                        value={selectedGroup || ''}
                        onChange={handleGroupChange}
                        disabled={loadingModule}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Выберите группу...
                        </MenuItem>
                        {groupOptions}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <Select
                        variant="standard"
                        value={selectedModule || ''}
                        onChange={handleModuleChange}
                        disabled={loadingModule}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Выберите модуль...
                        </MenuItem>
                        {moduleOptions}
                    </Select>
                </FormControl>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <TextField
                    variant="standard"
                    placeholder="Поиск по критериям"
                    value={searchQuery}
                    fullWidth
                    onChange={handleSearch}
                />
            </Box>
            {loading ? (
                <Loader />
            ) : viewMode === 'table' ? (
                <EnhancedTable deleteCriterion={deleteCriterion} filteredCriterion={filteredCriterion} />
            ) : (
                filteredCriterion.map(item => (
                    <Box key={item.id} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: '4px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                            <Typography variant="h6">
                                <Link to={`/criterion/${item.id}/`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    {item.title} – {item.weight}
                                </Link>
                            </Typography>
                            <DeleteDialog
                                id={item.id}
                                name={item.title}
                                onDelete={deleteCriterion}
                            />
                        </Box>
                        <Typography>
                            Описание: <br />
                            {item.description || 'Нет описания'}
                        </Typography>
                        <Typography>Группа: {item.group.name}</Typography>
                        <Typography>{new Date(item.created_at).toLocaleString()}</Typography>
                    </Box>
                ))
            )}
        </Box>
    );
};

export default Index;