import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from '../../service/axios';
import {toast} from 'react-hot-toast';
import {Box, Button, FormControl, TextField, Typography} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Loader from "../../components/Loader";

const Id = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [groups, setGroups] = useState([]);
    const [module, setModule] = useState({
        title: '',
        max_score: 0,
        group: ''
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`/modules/${id}/`)
            .then(res => {
                setModule({
                    ...res.data,
                    group: res.data.group.id
                });
                setLoading(false);
            })
            .catch(error => {
                console.error('Ошибка при получении данных модуля:', error);
                toast.error('Ошибка при получении данных модуля.', {
                    position: 'top-right'
                });
                setLoading(false);
            });

        // Получаем список групп
        axios.get('/groups/')
            .then(response => {
                setGroups(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.warn('Ошибка при получении групп:', error);
                toast.error('Ошибка при получении групп.', {
                    position: 'top-right'
                });
                setLoading(false);
            });
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();
        axios.put(`/modules/${id}/`, module)
            .then(res => {
                toast.success('Модуль успешно обновлен.', {
                    position: 'top-right'
                });
                navigate('/');
            })
            .catch(error => {
                console.error('Ошибка при обновлении модуля:', error);
                toast.error('Ошибка при обновлении модуля.', {
                    position: 'top-right'
                });
            });
    };

    const groupOptions = groups.map(group => ({
        value: group.id,
        label: group.name
    }));

    return (
        <Box sx={{p: 3}}>
            <Typography variant="h4" gutterBottom>
                Изменение модуля
            </Typography>
            {loading ? (
                <Loader/>
            ) : (
                <form onSubmit={handleSubmit}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="title"
                            label="Название модуля"
                            value={module.title}
                            onChange={(e) => setModule({...module, title: e.target.value})}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            id="group"
                            options={groupOptions}
                            value={groupOptions.find(option => option.value === module.group) || null}
                            onChange={(event, newValue) => setModule(prevModule => ({
                                ...prevModule,
                                group: newValue ? newValue.value : null
                            }))}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Выберите группу"
                                                                variant="standard"/>}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            noOptionsText="Нет вариантов"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="max_score"
                            label="Максимальный балл"
                            type="number"
                            inputProps={{step: '0.01'}}
                            value={module.max_score}
                            onChange={(e) => setModule({...module, max_score: e.target.value})}
                            variant="standard"
                        />
                    </FormControl>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{mt: 2}}
                    >
                        Сохранить
                    </Button>
                </form>
            )}
        </Box>
    );
};

export default Id;