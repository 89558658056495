import React from 'react';
import {useDispatch} from 'react-redux';
import {logout} from '../redux/slice/authSlice';
import {Navigate, Outlet} from 'react-router-dom';

const Protected = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const isAuthenticated = !!token;


    React.useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        }
    }, [dispatch]);

    return isAuthenticated ? <Outlet/> : <Navigate to="/login/"/>;
};

export default Protected;
