import React from 'react';
import { useDispatch } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ViewDayIcon from '@mui/icons-material/ViewDay'; // Пример иконки для Header
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'; // Пример иконки для Sidebar
import { setLayout } from '../../redux/slice/themeSlice'; // Импорт действия для изменения layout

const LayoutSelector = () => {
    const dispatch = useDispatch();

    const handleChange = (layoutType) => {
        dispatch(setLayout(layoutType)); // Обновляем состояние layout
    };

    return (
        <List dense>
            <ListItem button onClick={() => handleChange('header')}>
                <ListItemIcon>
                    <ViewDayIcon />
                </ListItemIcon>
                <ListItemText primary="Оформление 1" />
            </ListItem>
            <ListItem button onClick={() => handleChange('sidebar')}>
                <ListItemIcon>
                    <ViewSidebarIcon />
                </ListItemIcon>
                <ListItemText primary="Оформление 2" />
            </ListItem>
        </List>
    );
};

export default LayoutSelector;