import React, { useEffect, useState } from 'react';
import axios from '../../service/axios';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField, Typography, Autocomplete } from '@mui/material';

const Edit = () => {
    const { groupId } = useParams();
    const navigate = useNavigate();
    const [groupData, setGroupData] = useState({ name: '', users: [] });
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    useEffect(() => {
        axios.get(`groups/${groupId}/`)
            .then(response => {
                setGroupData({
                    ...response.data,
                    users: response.data.users
                });
                setSelectedUsers(response.data.users.map(user => ({
                    id: user.id,
                    full_name: user.full_name
                })));
            })
            .catch(error => {
                toast.error('Ошибка при загрузке данных о группе',{
                    position: 'top-right'
                });
                console.error(error);
            });
        axios.get('students/not/group/')
            .then(response => {
                setAllUsers(response.data);
            })
            .catch(error => {
                toast.error('Ошибка при загрузке списка студентов',{
                    position: 'top-right'
                });
                console.error(error);
            });
    }, [groupId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const dataToSend = {
            ...groupData,
            users: selectedUsers.map(user => user.id)
        };
        axios.put(`groups/${groupId}/`, dataToSend)
            .then(response => {
                toast.success('Группа успешно обновлена',{
                    position: 'top-right'
                });
                navigate('/groups');
            })
            .catch(error => {
                toast.error('Ошибка при обновлении группы',{
                    position: 'top-right'
                });
                console.error(error);
            });
    };

    const handleNameChange = (e) => {
        setGroupData({ ...groupData, name: e.target.value });
    };

    const handleUserChange = (event, newValue) => {
        setSelectedUsers(newValue);
    };

    const options = allUsers.map(user => ({
        id: user.id,
        full_name: user.full_name
    }));

    return (
        <Box
            sx={{
                p: 3,
                bgcolor: 'background.paper',
                borderRadius: 2,
            }}
        >
            <Typography variant="h3" gutterBottom>
                Редактирование группы
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                <TextField
                    id="name"
                    name="name"
                    label="Название группы"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    value={groupData.name || ''}
                    onChange={handleNameChange}
                />
                <Autocomplete
                    multiple
                    id="students"
                    options={options}
                    getOptionLabel={(option) => option.full_name}
                    value={selectedUsers}
                    onChange={handleUserChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Студенты"
                            variant="standard"
                            margin="normal"
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    noOptionsText={'Нет доступных студентов'}
                    loadingText={'Загрузка...'}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Сохранить изменения
                </Button>
            </Box>
        </Box>
    );
};

export default Edit;