import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    MenuItem,
    Select,
    Box,
    Typography
} from '@mui/material';
import { toast } from 'react-hot-toast';
import axios from "../../service/axios";

const ExportModal = ({ showModal, setShowModal, selectedGroup }) => {
    const [format, setFormat] = useState('xlsx'); // Формат по умолчанию
    const [separateSheets, setSeparateSheets] = useState(false); // Опция для отдельных листов
    const [downloadHandouts, setDownloadHandouts] = useState(false); // Опция для раздаточного материала
    const [allResults, setAllResults] = useState(false); // Опция для общей ведомости

    // Функция для скачивания файла
    const downloadFile = (groupId, format, separateSheets, handouts, allResults) => {
        axios.get(`results/${groupId}/all/`, {
            params: {
                export: format,
                separate_sheets: separateSheets,
                handouts: handouts,
                all_results: allResults
            },
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const date = new Date().toLocaleDateString().replace(/\//g, '-');
                const filename = handouts
                    ? `Раздаточный_материал-${date}.xlsx`
                    : `Результаты-${date}.${separateSheets ? 'zip' : format}`;
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error(`Ошибка при скачивании ${format}:`, error);
                toast.error(`Ошибка при скачивании ${format}`, {
                    position: 'top-right'
                });
            });
    };

    // Обработчик нажатия кнопки "Скачать"
    const handleDownload = () => {
        if (selectedGroup) {
            const toastId = toast.loading('Загрузка...', {
                position: 'top-right'
            });
            downloadFile(selectedGroup, format, separateSheets, downloadHandouts, allResults);
            toast.dismiss(toastId);
            setShowModal(false); // Закрытие модального окна
        } else {
            toast.error('Не выбран ID группы', {
                position: 'top-right'
            });
        }
    };

    // Обработчик изменения чекбоксов
    const handleCheckboxChange = (checkbox) => {
        if (checkbox === 'separateSheets') {
            setSeparateSheets(!separateSheets);
            setDownloadHandouts(false);
            setAllResults(false);
        } else if (checkbox === 'downloadHandouts') {
            setSeparateSheets(false);
            setDownloadHandouts(!downloadHandouts);
            setAllResults(false);
        } else if (checkbox === 'allResults') {
            setSeparateSheets(false);
            setDownloadHandouts(false);
            setAllResults(!allResults);
        }
    };

    return (
        <Dialog open={showModal} onClose={() => setShowModal(false)} fullWidth>
            <DialogTitle>Настройки печати</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" gutterBottom>
                    Формат файла:
                </Typography>
                <Select
                    fullWidth
                    value={format}
                    onChange={(e) => setFormat(e.target.value)}
                >
                    <MenuItem value="xlsx">Excel (xlsx)</MenuItem>
                </Select>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={separateSheets}
                            onChange={() => handleCheckboxChange('separateSheets')}
                        />
                    }
                    label="Отдельные листы для каждого студента"
                    disabled={downloadHandouts || allResults}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={downloadHandouts}
                            onChange={() => handleCheckboxChange('downloadHandouts')}
                        />
                    }
                    label="Скачать раздаточный материал"
                    disabled={separateSheets || allResults}
                />
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allResults}
                                onChange={() => handleCheckboxChange('allResults')}
                            />
                        }
                        label="Скачать общую ведомость"
                        disabled={separateSheets || downloadHandouts}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => setShowModal(false)} color="error" variant={'contained'}>
                    Отмена
                </Button>
                <Button onClick={handleDownload} color="primary" variant={'contained'}>
                    Скачать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportModal;