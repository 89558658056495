import React from 'react';
import axios from "../../../service/axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "../../../redux/slice/authSlice";
import { Box, Button, Container, Snackbar, TextField, Typography } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import { useForm } from 'react-hook-form';

const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        try {
            const result = await axios.post('token/', {
                username: data.username,
                password: data.password,
            });
            dispatch(setLogin({
                user: result.data.user_id,
                token: result.data.token,
                first_name: result.data.first_name,
                last_name: result.data.last_name,
                username: result.data.username,
            }));
            setSnackbarMessage('Вы успешно авторизовались!');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            navigate('/');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.non_field_errors) {
                setSnackbarMessage(error.response.data.non_field_errors[0]);
            } else {
                setSnackbarMessage('Произошла ошибка. Пожалуйста, попробуйте снова.');
            }
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            console.warn(error);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container maxWidth="xs" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    p: 2,
                    boxShadow: 1,
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Авторизация
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%', mt: 1 }}>
                    <TextField
                        id="username"
                        label="Логин"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        required
                        {...register('username', { required: 'Логин обязателен' })}
                        error={!!errors.username}
                        helperText={errors.username?.message}
                    />
                    <TextField
                        id="password"
                        label="Пароль"
                        type="password"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        required
                        {...register('password', { required: 'Пароль обязателен' })}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Войти <LoginIcon sx={{ ml: 1 }} />
                    </Button>
                </Box>
                <Typography variant="body2" color="text.secondary">
                    Авторизация в систему оценки FIRPO
                </Typography>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                key={`${snackbarSeverity}-${openSnackbar}`}
                message={snackbarMessage}
                action={
                    <Button color="inherit" onClick={handleCloseSnackbar}>
                        Закрыть
                    </Button>
                }
            />
        </Container>
    );
};

export default Index;