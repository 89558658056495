import React, {Fragment, useEffect, useState} from "react";
import axios from "../../service/axios";
import {toast} from "react-hot-toast";
import {Link} from "react-router-dom";
import {Box, Button, MenuItem, Select, Typography} from '@mui/material';
import ExportModal from "../../components/ExportModal";
import Loader from "../../components/Loader";
import DeleteDialog from "../../components/DeleteDialog";

const Index = () => {
    const [groups, setGroups] = useState([]);
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [studentsNotGroup, setStudentsNotGroup] = useState([]);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        axios.get('groups/')
            .then(response => {
                setGroups(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.warn(error);
            });
        axios.get('students/not/group/')
            .then(response => {
                setStudentsNotGroup(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.warn(error);
            });
    }, []);

    const handleGroupChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedGroup(selectedOption);
        if (selectedOption) {
            axios.get(`groups/${selectedOption}/students/`)
                .then(response => {
                    setStudents(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.warn(error);
                });
        } else {
            setStudents([]);
        }
    };

    const deleteStudent = async (id) => {
        toast.dismiss();
        toast.loading('Обрабатываю запрос на удаление',{
            position: 'top-right'
        })
        axios.delete(`/students/${id}/`)
            .then(response => {
                setStudents(students.filter(student => student.id !== id));
                toast.dismiss();
                toast.success(response.data.message, {
                    position: 'top-right'
                });
            })
            .catch(error => {
                console.warn(error);
            });
    }

    const deleteStudentNotGroup = async (id) => {
        toast.dismiss();
        toast.loading('Обрабатываю запрос на удаление',{
            position: 'top-right'
        })
        axios.delete(`/students/${id}/`)
            .then(response => {
                setStudentsNotGroup(studentsNotGroup.filter(student => student.id !== id));
                toast.dismiss();
                toast.success(response.data.message, {
                    position: 'top-right'
                });
            })
            .catch(error => {
                console.warn(error);
            });
    }

    return (
        <>
            <Box style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginBottom: '16px'
            }}>
                <Typography variant="h3">Студенты</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginBottom: '16px'
                }}>
                    <Button variant="contained" color="success" component={Link} to='/students/create/'>Создать</Button>
                    <Button variant="contained" sx={{ml: 1}} color="primary" component={Link}
                            to='/students/upload/'>Загрузить</Button>
                </Box>
            </Box>
            <Box style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginBottom: '16px'
            }}>
                <Select
                    disabled={loading}
                    onChange={handleGroupChange}
                    value={selectedGroup || ''}
                    displayEmpty
                    variant={'standard'}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return 'Выберите группу для отображения студентов';
                        }
                        return groups.find(group => group.id === selected)?.name;
                    }}
                >
                    {groups.map(group => (
                        <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                    ))}
                </Select>
                {selectedGroup && (
                    <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>Печатать</Button>
                )}
            </Box>
            {loading ? (
                <Loader/>
            ) : (
                <>
                    {!selectedGroup ? (
                        <Typography variant="h4">Выберите группу для отображения студентов</Typography>
                    ) : (
                        <Box>
                            {students.map(student => (
                                <Box key={student.id} style={{
                                    marginBottom: '16px',
                                    padding: '16px',
                                    border: '1px solid #ddd',
                                    borderRadius: '4px'
                                }}>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Box>
                                            <Typography variant="h6">
                                                <Link to={`/students/${student.id}/`} style={{
                                                    textDecoration: 'none',
                                                    color: 'inherit'
                                                }}>{student.full_name}</Link>
                                            </Typography>
                                            <Typography
                                                variant={'subtitle2'}>Логин: {student.username ? student.username : 'Данные не создавались'}</Typography>
                                            <Typography
                                                variant={'subtitle2'}>Пароль: {student.password ? student.password : 'Данные не создавались'}</Typography>
                                        </Box>
                                        <DeleteDialog
                                            id={student.id}
                                            name={student.full_name}
                                            onDelete={deleteStudent}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    )}
                    {studentsNotGroup.length > 0 && (
                        <Box
                            style={{marginTop: '16px', padding: '16px', border: '1px solid #ddd', borderRadius: '4px'}}>
                            <Typography variant="h6">Студенты которые не добавлены ни в одну группу:</Typography>
                            {studentsNotGroup.map(student => (
                                <Box key={student.id} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '8px'
                                }}>
                                    <Typography variant="body1">{student.full_name}</Typography>
                                    <DeleteDialog
                                        id={student.id}
                                        name={student.full_name}
                                        onDelete={deleteStudentNotGroup}
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                </>
            )}
            {selectedGroup && (
                <ExportModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    selectedGroup={selectedGroup}
                />
            )}
            {showModal && <Box className="modal-backdrop fade show"></Box>}
        </>
    );
};

export default Index;