import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "../../service/axios";
import {toast} from "react-hot-toast";
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography
} from '@mui/material';
import Loader from "../../components/Loader";
import DeleteDialog from "../../components/DeleteDialog";

const Index = () => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('groups/')
            .then(response => {
                setLoading(false);
                setGroups(response.data);
            })
            .catch(error => {
                console.warn(error);
                setLoading(false); // Ensure loading state is stopped on error
            });
    }, []);

    const deleteGroup = async (id) => {
        toast.dismiss();
        axios.delete(`groups/${id}/`)
            .then(response => {
                setGroups(groups.filter(group => group.id !== id));
                toast.success('Группа удалена');
            })
            .catch(error => {
                console.warn(error);
                toast.error(error.response?.data?.message || 'Произошла ошибка');
            });
    };

    const deleteUser = async (groupId, userId) => {
        axios.delete(`groups/${groupId}/delete/student/${userId}/`)
            .then(response => {
                setGroups(groups.map(group => {
                    if (group.id === groupId) {
                        return {
                            ...group,
                            users: group.users.filter(user => user.id !== userId)
                        };
                    }
                    return group;
                }));
                toast.success(response.data.message);
            })
            .catch(error => {
                console.warn(error);
                toast.error('Произошла ошибка');
            });
    };

    if (loading) {
        return (
            <Loader/>
        );
    }

    return (
        <Box sx={{p: 2}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    mb: 2
                }}
            >
                <Typography variant="h3">Группы</Typography>
                <Button
                    variant="contained"
                    color="success"
                    component={Link}
                    to="/groups/create/"
                >
                    Создать
                </Button>
            </Box>
            {groups.map(group => (
                <Card key={group.id} sx={{mb: 2}}>
                    <CardContent>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            flexDirection: {xs: 'column', sm: 'row'}, // Stack vertically on small screens
                        }}>
                            <Typography variant="h5">
                                <Link to={`${group.id}/`} style={{textDecoration: 'none', color: 'inherit'}}>
                                    {group.name}
                                </Link>
                            </Typography>
                            <DeleteDialog
                                id={group.id}
                                name={`Группу: ${group.name}`}
                                onDelete={deleteGroup}
                            />
                        </Box>
                        <Divider sx={{my: 1}}/>
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                        >
                            {group.users.map(item => (
                                <ListItem
                                    key={item.id}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'start',
                                        flexDirection: {xs: 'column', sm: 'row'}, // Stack vertically on small screens
                                        py: 1,
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Link to={`/students/${item.id}/`}
                                                  style={{textDecoration: 'none', color: 'inherit'}}>
                                                {item.full_name}
                                            </Link>
                                        }
                                        sx={{flex: 1}} // Allow ListItemText to take up available space
                                    />
                                    <DeleteDialog
                                        id={group.id}
                                        name={item.full_name}
                                        onDelete={deleteUser}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="body2" color="text.secondary">
                            {new Date(group.created_at).toLocaleString()}
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

export default Index;