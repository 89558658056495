import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slice/authSlice';
import criteriaReducer from './slice/criteriaSlice';
import themeReducer from './slice/themeSlice';

export default configureStore({
    reducer: {
        auth: authReducer,
        criteria: criteriaReducer,
        theme: themeReducer,
    },
});
