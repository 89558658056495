import React, {useEffect, useState} from 'react';
import axios from "../../service/axios";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";

const Create = () => {
    const navigate = useNavigate();
    const admin = useSelector(state => state.auth.user);


    const [name, setName] = useState('');
    const [students, setStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);

    useEffect(() => {
        axios.get('students/not/group/')
            .then(response => {
                setStudents(response.data);
            })
            .catch(error => {
                toast.error('Ошибка при загрузке студентов', {
                    position: 'top-right',
                });
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('groups/', {
            name,
            users: selectedStudents,
            admin
        })
            .then(response => {
                toast.success('Группа успешно создана!', {
                    position: 'top-right',
                });
                navigate('/groups');
            })
            .catch(error => {
                toast.error('Ошибка при создании группы', {
                    position: 'top-right',
                });
                console.warn(error);
            });
    };

    const handleChange = (event) => {
        const {value} = event.target;
        setSelectedStudents(value);
    };

    return (
        <Box
            sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: 'background.paper',
            }}
        >
            <Typography variant="h3" gutterBottom>
                Создание группы
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{width: '100%'}}>
                <TextField
                    id="name"
                    label="Название группы"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                />
                <FormControl fullWidth variant="standard" margin="normal">
                    <InputLabel id="select-students-label">Студенты</InputLabel>
                    <Select
                        labelId="select-students-label"
                        id="select-students"
                        multiple
                        value={selectedStudents}
                        onChange={handleChange}
                        label="Студенты"
                        renderValue={(selected) => (
                            <>
                                {selected.map((id) => {
                                    const student = students.find(student => student.id === id);
                                    return student ? student.full_name : null;
                                }).join(', ')}
                            </>
                        )}
                    >
                        {students.map(student => (
                            <MenuItem key={student.id} value={student.id}>
                                {student.full_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{mt: 2}}
                >
                    Создать
                </Button>
            </Box>
        </Box>
    );
};

export default Create;