import React, { useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slice/authSlice';
import { setLayout, toggleTheme } from '../../redux/slice/themeSlice';
import NavLink from '../NavLink';
import {
    AppBar,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
    Tooltip,
    Divider,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const Index = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const mode = useSelector(state => state.theme.mode); // Получаем текущий режим темы
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null); // Для управления меню

    const isMenuOpen = Boolean(anchorEl); // Проверяем, открыто ли меню
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget); // Устанавливаем элемент, к которому привязан Menu
    };
    const handleMenuClose = () => {
        setAnchorEl(null); // Закрываем меню
    };

    const handleLayoutChange = (layoutType) => {
        dispatch(setLayout(layoutType)); // Обновляем состояние layout
        handleMenuClose(); // Закрываем меню после выбора
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const exit = () => {
        dispatch(logout());
        window.location.href = '/login/';
    };

    const handleToggleTheme = () => {
        dispatch(toggleTheme());
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AppBar position="static">
            <Toolbar>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6" noWrap component="div">
                        <RouteLink to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            Система Оценивания
                        </RouteLink>
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {!isMobile && (
                            <Tooltip title="Переключить тему">
                                <IconButton edge="end" color="inherit" onClick={handleToggleTheme}>
                                    {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
                                </IconButton>
                            </Tooltip>
                        )}
                        {auth.isAuthenticated && (
                            <>
                                {isMobile ? (
                                    <>
                                        <IconButton edge="start" color="inherit" onClick={handleDrawerToggle} aria-label="menu">
                                            <MenuIcon />
                                        </IconButton>
                                        <Drawer
                                            anchor="left"
                                            open={drawerOpen}
                                            onClose={handleDrawerToggle}
                                        >
                                            <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle}>
                                                <IconButton onClick={handleDrawerToggle} color="inherit">
                                                    <CloseIcon />
                                                </IconButton>
                                                <IconButton
                                                    edge="end"
                                                    color="inherit"
                                                    onClick={handleToggleTheme}
                                                    sx={{
                                                        margin: '16px',
                                                        fontSize: '24px',
                                                        width: '40px',
                                                        height: '40px'
                                                    }}
                                                >
                                                    {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
                                                </IconButton>
                                                <List>
                                                    <NavLink text="Группа" to="/groups/" />
                                                    <NavLink text="Модули" to="/" />
                                                    <NavLink text="Критерии" to="/criteria/" />
                                                    <NavLink text="Студенты" to="/students/" />
                                                    <NavLink text="Оценивание" to="/results/" />
                                                    <ListItem button onClick={exit}>
                                                        <ListItemText primary="Выйти" />
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </Drawer>
                                    </>
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <NavLink text="Группа" to="/groups/" />
                                        <NavLink text="Модули" to="/" />
                                        <NavLink text="Критерии" to="/criteria/" />
                                        <NavLink text="Студенты" to="/students/" />
                                        <NavLink text="Оценивание" to="/results/" />
                                        <Button color="inherit" onClick={exit}>
                                            Выйти
                                        </Button>
                                        <Divider orientation="vertical" flexItem />
                                        <IconButton color="inherit" onClick={handleMenuOpen}>
                                            <ListItemText primary="Оформление" />
                                            {isMenuOpen ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl} // Привязываем меню к элементу
                                            open={isMenuOpen}
                                            onClose={handleMenuClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 224, // Высота меню
                                                    width: '20ch',
                                                },
                                            }}
                                        >
                                            <MenuItem onClick={() => handleLayoutChange('header')}>
                                                <ListItemIcon>
                                                    <ViewDayIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Оформление 1" />
                                            </MenuItem>
                                            <MenuItem onClick={() => handleLayoutChange('sidebar')}>
                                                <ListItemIcon>
                                                    <ViewSidebarIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Оформление 2" />
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Index;