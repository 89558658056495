import React, { useState } from 'react';
import axios from "../../service/axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {Button, Typography, Box, styled, FormControl} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import example from "../../static/example.csv";
import {CloudDownloadIcon} from "lucide-react";

// Стилизованный компонент для скрытого input
const VisuallyHiddenInput = styled('input')({
    position: 'absolute',
    width: '1px',
    height: '1px',
    margin: '-1px',
    padding: '0',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: '0',
});

const StudentsUploads = () => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    const handleUpload = (e) => {
        e.preventDefault();
        if (!file) {
            toast.error('Выберите файл для загрузки',{
                position: 'top-right'
            });
            return;
        }
        const formData = new FormData();
        formData.append('csv_file', file);

        axios.post('students/upload/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then((response) => {
                toast.success(response.data.message,{
                    position: 'top-right'
                });
                navigate('/students/');
            })
            .catch((error) => {
                console.warn(error);
                toast.error('Ошибка при загрузке файла',{
                    position: 'top-right'
                });
            });
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h3">
                    Загрузка файла
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<CloudDownloadIcon />}
                    onClick={() => window.location.href = example}  // Изменяем URL напрямую
                >
                    Скачать образец
                </Button>
            </Box>
            <form onSubmit={handleUpload}>
                <FormControl fullWidth margin="normal">
                    <Button
                        component="label"
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        fullWidth
                    >
                        Загрузить файл
                        <VisuallyHiddenInput
                            type="file"
                            accept=".csv"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </Button>
                </FormControl>
                <Box sx={{ mt: 2 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!file}
                    >
                        Загрузить
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default StudentsUploads;