import React, {useEffect, useState} from 'react';
import axios from '../../service/axios';
import {Link} from 'react-router-dom';
import {toast} from 'react-hot-toast';
import {Autocomplete, Box, Button, Card, CardActions, CardContent, TextField, Typography} from '@mui/material';
import Loader from "../../components/Loader";
import DeleteDialog from "../../components/DeleteDialog";

const Index = () => {
    const [modules, setModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);

    // Fetch all groups
    useEffect(() => {
        axios.get('groups/')
            .then(response => {
                setGroups(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.warn(error);
                setLoading(false);
            });
    }, []);

    // Fetch modules based on selected group
    useEffect(() => {
        if (selectedGroup) {
            setLoading(true);
            axios.get(`module/${selectedGroup.value}/modules/`)
                .then(response => {
                    setModules(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.warn(error);
                    setLoading(false);
                });
        } else {
            // Fetch all modules if no group is selected
            axios.get('modules/')
                .then(response => {
                    setModules(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.warn(error);
                    setLoading(false);
                });
        }
    }, [selectedGroup]);

    // Handle module deletion
    const deleteModule = async (id) => {
        toast.dismiss();
        axios.delete(`/modules/${id}/`)
            .then(response => {
                setModules(modules.filter(module => module.id !== id));
                toast.success(response.data.message, {
                    position: 'top-right'
                });
            })
            .catch(error => {
                console.warn(error);
                toast.error(error.response.data.message, {
                    position: 'top-right'
                });
            });
    };

    // Options for Autocomplete
    const groupOptions = groups.map(group => ({
        value: group.id,
        label: group.name
    }));

    return (
        <Box sx={{p: 2}}>
            <Box sx={{display: 'flex', mb: 2, justifyContent: 'space-between', alignItems: 'center',}}>
                <Typography variant="h3" textAlign="center">
                    Модули
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', mt: 2}}>
                    <Button variant="contained" color="success" component={Link} to="/module/create" sx={{mb: 1}}>
                        Создать
                    </Button>
                    <Button variant="contained" color="primary" component={Link} to="/upload">
                        Загрузить
                    </Button>
                </Box>
            </Box>
            <Autocomplete
                disablePortal
                id="group-select"
                options={groupOptions}
                value={selectedGroup}
                onChange={(event, newValue) => setSelectedGroup(newValue)}
                renderInput={(params) => <TextField {...params} label="Выберите группу" variant="standard"/>}
                sx={{mb: 2}}
                noOptionsText="Нет доступных групп"
            />
            {loading ? (
                <Loader/>
            ) : (
                <Box sx={{p: 2}}> {/* Основной контейнер с отступами */}
                    {modules.map(module => (
                        <Card
                            key={module.id}
                            sx={{
                                width: '100%', // Карточка занимает всю ширину
                                mb: 2, // Отступ снизу карточек
                                p: 2,
                                boxShadow: 1, // Тень карточек
                                borderRadius: '4px'
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    <Link to={`module/${module.id}/`}
                                          style={{textDecoration: 'none', color: 'inherit'}}>
                                        {module.title} - {module.max_score} баллов
                                    </Link>
                                </Typography>
                                <Typography sx={{mt: 1}}>Группа: {module.group.name}</Typography>
                                <Typography>{new Date(module.created_at).toLocaleString()}</Typography>
                            </CardContent>
                            <CardActions>
                                <DeleteDialog
                                    id={module.id}
                                    name={`Модуль: ${module.title}`}
                                    onDelete={deleteModule}
                                />
                            </CardActions>
                        </Card>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default Index;