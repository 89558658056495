import {ListItem, ListItemText} from "@mui/material";
import {Link as RouteLink} from "react-router-dom";
import React from "react";

const Index = ({ text, to }) => (
    <ListItem button component={RouteLink} to={to}>
        <ListItemText primary={text} />
    </ListItem>
);

export default Index;