import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../service/axios';
import { toast } from 'react-hot-toast';
import * as XLSX from 'xlsx';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import example from '../../static/example.xlsx';
import {CloudDownloadIcon} from "lucide-react";

const Index = () => {
    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [open, setOpen] = useState(false);
    const [moduleTotals, setModuleTotals] = useState({});
    const [exceedingModules, setExceedingModules] = useState([]);
    const [isFileValid, setIsFileValid] = useState(true);
    const navigate = useNavigate();

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file) {
            toast.error('Файл не выбран.');
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await axios.post('/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            toast.success(response.data.message,{
                position: 'top-right'
            });
            navigate('/students/');
        } catch (error) {
            console.warn(error);
            toast.error('Произошла ошибка при загрузке.',{
                position: 'top-right'
            });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const data = new Uint8Array(event.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
                    const { moduleTotals, exceedingModules } = calculateTotalCriteriaPoints(json);
                    setFileData(json);
                    setModuleTotals(moduleTotals);
                    setExceedingModules(exceedingModules);
                } catch (error) {
                    console.error('Ошибка обработки файла:', error);
                    setIsFileValid(false);
                }
            };
            reader.onerror = (error) => {
                console.error('Ошибка чтения файла:', error);
                setIsFileValid(false);
            };
            reader.readAsArrayBuffer(file);
            setOpen(true);
        } else {
            setIsFileValid(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setIsFileValid(true);
    };

    const calculateTotalCriteriaPoints = (rows) => {
        const moduleTotals = {};
        let currentModule = '';
        const exceedingModules = [];

        rows.forEach(row => {
            if (row['Модуль']) {
                currentModule = row['Модуль'];
                moduleTotals[currentModule] = {
                    maxModulePoints: parseFloat(row['Макс. баллы модуля']),
                    totalCriteriaPoints: 0,
                    rows: []
                };
            }

            if (currentModule) {
                const criteriaPoints = parseFloat(row['Макс. баллы критерия']);
                moduleTotals[currentModule].totalCriteriaPoints += criteriaPoints;
                moduleTotals[currentModule].rows.push(row);
            }
        });

        Object.keys(moduleTotals).forEach(moduleName => {
            const moduleTotal = moduleTotals[moduleName];
            if (moduleTotal.totalCriteriaPoints > moduleTotal.maxModulePoints) {
                exceedingModules.push({
                    module: moduleName,
                    excess: moduleTotal.totalCriteriaPoints - moduleTotal.maxModulePoints
                });
            }
        });

        return { moduleTotals, exceedingModules };
    };

    const isButtonDisabled = exceedingModules.length > 0 || !isFileValid;

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h3">Загрузка всех данных</Typography>
                <Button
                    variant="contained"
                    startIcon={<CloudDownloadIcon />}
                    onClick={() => window.location.href = example}  // Изменяем URL напрямую
                >
                    Скачать образец
                </Button>
            </Box>
            <form onSubmit={handleUpload}>
                <Button
                    fullWidth
                    variant="outlined"
                    component="label"
                    startIcon={<VisibilityIcon />}
                >
                    Выберите файл
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                    />
                </Button>
                <Box mt={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isButtonDisabled}
                    >
                        Загрузить
                    </Button>
                </Box>
            </form>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle sx={{ fontWeight: 'bold' }}>Предварительный просмотр данных</DialogTitle>
                <DialogContent>
                    {!isFileValid && (
                        <Box mb={2}>
                            <Typography color="error">
                                Ошибка при обработке файла. Пожалуйста, выберите другой файл.
                            </Typography>
                        </Box>
                    )}
                    {exceedingModules.length > 0 && (
                        <Box mb={2}>
                            {exceedingModules.map((item, index) => (
                                <Typography key={index} color="error">
                                    В модуле "{item.module}" перебор с баллами на {item.excess} баллов.
                                </Typography>
                            ))}
                        </Box>
                    )}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell variant="body">Группа</TableCell>
                                    <TableCell>Модуль</TableCell>
                                    <TableCell>Макс. баллы модуля</TableCell>
                                    <TableCell>Критерий</TableCell>
                                    <TableCell>Макс. баллы критерия</TableCell>
                                    <TableCell>Описание критерия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fileData.map((row, index) => {
                                    const moduleName = row['Модуль'];
                                    const moduleTotal = moduleTotals[moduleName];
                                    const isExceeding = moduleTotal && moduleTotal.totalCriteriaPoints > moduleTotal.maxModulePoints;
                                    return (
                                        <TableRow key={index} style={{ backgroundColor: isExceeding ? 'rgba(255, 0, 0, 0.2)' : 'inherit' }}>
                                            <TableCell>{row['Группа']}</TableCell>
                                            <TableCell>{moduleName}</TableCell>
                                            <TableCell variant="head">{row['Макс. баллы модуля']}</TableCell>
                                            <TableCell>{row['Критерий']}</TableCell>
                                            <TableCell>{row['Макс. баллы критерия']}</TableCell>
                                            <TableCell>{row['Описание критерия']}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="error">
                        Отмена
                    </Button>
                    <Button
                        onClick={handleUpload}
                        variant="contained"
                        color="success"
                        disabled={isButtonDisabled}
                    >
                        Подтвердить и загрузить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Index;